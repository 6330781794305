<template>
  <div class="hotel">
    <div class="_title" v-if="title">订单详情</div>
    <div class="_content" :class="className">
      <v-card flat class="pb-12" min-height="500">
        <v-card-text class="pa-0">
          <div class="d-flex form-title">
            <div class="line mr-2 mb-8"></div>
            订单信息
          </div>
          <div>
            <div class="group d-flex">
              <div class="key">酒店名称</div>
              <div class="value">{{ hotel.hotelName }}</div>
            </div>
            <div class="group d-flex">
              <div class="key">房间类型</div>
              <div :class="$store.state.navigation ? '' : 'd-flex'" class="value" >
                <div v-if="hotel.standardSingleNum > 0" class="mr-2">
                  标准间(含单早) {{ hotel.standardSingleNum }} 间
                </div>
                <div v-if="hotel.standardDoubleNum > 0" class="mr-2">
                  标准间(含双早) {{ hotel.standardDoubleNum }} 间
                </div>
                <div v-if="hotel.bigbedSingleNum > 0" class="mr-2">
                  大床房(含单早) {{ hotel.bigbedSingleNum }} 间
                </div>
                <div v-if="hotel.bigbedDoubleNum > 0" class="mr-2">
                  大床房(含双早) {{ hotel.bigbedDoubleNum }} 间
                </div>
              </div>
            </div>
            <div class="group d-flex">
              <div class="key">入住时间~退房时间</div>
              <div class="value">
                <span>{{ hotel.beginTime | format }}</span
                >~<span>{{ hotel.endTime | format }}</span>
              </div>
            </div>
            <div class="group d-flex">
              <div class="key">联系人信息</div>
              <div class="value">
                <span>{{ hotel.contactName }}</span>
                <span>{{ hotel.contactPhone }}</span>
              </div>
            </div>
            <div class="group d-flex">
              <div class="key">预定时间</div>
              <div class="value">{{ hotel.createTime }}</div>
            </div>
          </div>

          <div class="d-flex form-title mt-5 mb-5">
            <div class="line mr-2"></div>
            入住人员登记
          </div>
          <div
            class="group d-flex"
            v-for="(item, i) in hotel.staffBodyList"
            :key="i"
          >
            <div class="key">
              {{ item.roomName }}
            </div>
            <div  >
              <div
              :class="$store.state.navigation ? '' : 'd-flex'"
                class="value"
                v-for="(value, index) in item.peopleList"
                :key="index"
              >
                <div class="mr-1 ml-1">
                  {{ value.name }} 
                </div>
                <div class="mr-1 ml-1">
                  {{ value.idCard }}
                </div>
                <div class="mr-1 ml-1">
                  {{ value.phone }}
                </div>
              </div>
            </div>
          </div>

          <v-btn
            style="min-width: 0 !important; width: 300px; margin: 0 auto"
            color="success"
            block
            class="mt-4"
            @click="userAction"
          >
            返回
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,

      hotel: {},
    };
  },
  created() {
    let id = this.$route.query.id;
    if (id) {
      this.getList(id);
    }
  },
  methods: {
    getList(id) {
      this.$request({
        url: "/hotel/detail",
        method: "get",
        params: {
          id: id,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.hotel = res.data;
        }
      });
    },
    userAction() {
      this.$router.push({ path: "/user", query: { num: 3 } });
    },
  },
  filters: {
    format(shijianchuo) {
      if(!shijianchuo){
        return ''
      }
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      // var h = time.getHours();
      // var mm = time.getMinutes();
      // var s = time.getSeconds();
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      return y + "-" + add0(m) + "-" + add0(d);
    },
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "tab-item",
    },
  },
};
</script>

<style lang="less" >
.hotel {
  padding: 0 10px;
  padding-bottom: 150px;

  .tips {
    font-size: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#666666, 0.3);
    margin-bottom: 20px;
    text-align: center;
  }
  .group {
    min-height: 50px;
    padding-left: 10px;
    .key {
      color: rgba(#666666, 0.5);
      margin-right: 20px;
      text-align: end;
      width: 130px;
    }
    .value {
      font-weight: bold;
    }
  }
  .form-title {
    font-size: 20px;
    font-weight: bold;
  }
  .form-content {
    height: 50px;
  }
  input {
    text-align: center !important;
  }
  .add {
    width: 19px;
    height: 19px;
    margin-left: 10px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .delete {
    width: 22px;
    height: 21px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .short-input {
    width: 50px;
  }
  .middle-input {
    width: 100px;
  }
  .long-input {
    width: 150px;
  }
  .line {
    height: 20px;
    width: 4px;
    border-radius: 20%;
    background: #1976d2;
  }
  .RMB {
    color: red;
  }
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  ._blue {
    color: #337fe5;
  }
  .desc-title {
    color: #666666;
  }
  .bold {
    font-weight: bold;
  }
  .required {
    position: relative;

    text-align: left;
    font-weight: bold;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
}
</style>
